import React from "react";

const CsvIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="24px" // Updated width to 24px
    height="24px" // Updated height to 24px
    viewBox="0 0 87 99"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        style={{ opacity: 1, fill: "#fefefe" }}
        d="M13.5,1.5C30.1667,1.5,46.8333,1.5,63.5,1.5C63.5,8.83333,63.5,16.1667,63.5,23.5C70.5,23.5,77.5,23.5,84.5,23.5C84.5,47.8333,84.5,72.1667,84.5,96.5C60.8333,96.5,37.1667,96.5,13.5,96.5C13.5,92.5,13.5,88.5,13.5,84.5C20.8173,85.3313,28.1506,85.998,35.5,86.5C36.9187,87.4511,38.5853,87.7845,40.5,87.5C40.5,68.5,40.5,49.5,40.5,30.5C38.5853,30.2155,36.9187,30.5489,35.5,31.5C28.8333,32.1667,22.1667,32.8333,15.5,33.5C13.5238,23.2191,12.8572,12.5524,13.5,1.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.854, fill: "#afafaf" }}
        d="M11.5,0.5C12.1667,0.833333,12.8333,1.16667,13.5,1.5C12.8572,12.5524,13.5238,23.2191,15.5,33.5C14.1667,34.8333,12.8333,34.8333,11.5,33.5C11.5,22.5,11.5,11.5,11.5,0.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#237347" }}
        d="M35.5,31.5C36.8333,31.5,38.1667,31.5,39.5,31.5C39.5,49.8333,39.5,68.1667,39.5,86.5C38.1667,86.5,36.8333,86.5,35.5,86.5C28.1506,85.998,20.8173,85.3313,13.5,84.5C12.8333,84.5,12.1667,84.5,11.5,84.5C7.95478,83.9541,4.45478,83.2874,1,82.5C0.500075,66.8369,0.333409,51.1702,0.5,35.5C4.23205,35.0157,7.89871,34.349,11.5,33.5C12.8333,34.8333,14.1667,34.8333,15.5,33.5C22.1667,32.8333,28.8333,32.1667,35.5,31.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#8cb69f" }}
        d="M35.5,31.5C36.9187,30.5489,38.5853,30.2155,40.5,30.5C40.5,49.5,40.5,68.5,40.5,87.5C38.5853,87.7845,36.9187,87.4511,35.5,86.5C36.8333,86.5,38.1667,86.5,39.5,86.5C39.5,68.1667,39.5,49.8333,39.5,31.5C38.1667,31.5,36.8333,31.5,35.5,31.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#eff5f1" }}
        d="M14.5,49.5C15.9778,49.238,17.3112,49.5713,18.5,50.5C20.1814,58.3605,21.6814,58.3605,23,50.5C24.1827,49.269,25.3493,49.269,26.5,50.5C22.3475,57.0966,23.0142,63.2633,28.5,69C26.7412,69.7975,25.0745,69.6309,23.5,68.5C22.4981,66.4899,21.4981,64.4899,20.5,62.5C19.218,64.1792,18.218,66.0126,17.5,68C16.1667,68.6667,14.8333,68.6667,13.5,68C15.3809,65.2386,16.7142,62.2386,17.5,59C16.3975,55.8595,15.3975,52.6928,14.5,49.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#8cc1a4" }}
        d="M72.5,68.5C72.5,74.8333,72.5,81.1667,72.5,87.5C71.5,87.5,70.5,87.5,69.5,87.5C69.7373,86.209,69.404,85.209,68.5,84.5C63.6076,89.3188,58.4409,89.6522,53,85.5C50.6335,81.0009,51.4668,77.1676,55.5,74C59.7402,72.7607,64.0735,71.9273,68.5,71.5C68.19,65.6966,65.19,63.5299,59.5,65C57.9194,65.7065,56.4194,66.5399,55,67.5C54.2797,65.8876,54.4464,64.3876,55.5,63C61.6081,60.3904,67.1081,61.2237,72,65.5C72.4828,66.448,72.6495,67.448,72.5,68.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#d6e9df" }}
        d="M72.5,68.5C73.4907,74.979,73.824,81.6457,73.5,88.5C71.8333,88.5,70.1667,88.5,68.5,88.5C68.5,87.1667,68.5,85.8333,68.5,84.5C69.404,85.209,69.7373,86.209,69.5,87.5C70.5,87.5,71.5,87.5,72.5,87.5C72.5,81.1667,72.5,74.8333,72.5,68.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#fbfcfb" }}
        d="M61.5,75.5C63.8333,75.5,66.1667,75.5,68.5,75.5C68.828,82.8474,65.4947,85.8474,58.5,84.5C55.3534,80.0808,56.3534,77.0808,61.5,75.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.517, fill: "#a3a2a2" }}
        d="M11.5,0.5C11.5,0.166667,11.5,-0.166667,11.5,-0.5C29.5,-0.5,47.5,-0.5,65.5,-0.5C72.1667,6.83333,79.1667,13.8333,86.5,20.5C86.5,46.5,86.5,72.5,86.5,98.5C61.5,98.5,36.5,98.5,11.5,98.5C11.5,93.8333,11.5,89.1667,11.5,84.5C12.1667,84.5,12.8333,84.5,13.5,84.5C13.5,88.5,13.5,92.5,13.5,96.5C37.1667,96.5,60.8333,96.5,84.5,96.5C84.5,72.1667,84.5,47.8333,84.5,23.5C77.5,23.5,70.5,23.5,63.5,23.5C63.5,16.1667,63.5,8.83333,63.5,1.5C46.8333,1.5,30.1667,1.5,13.5,1.5C12.8333,1.16667,12.1667,0.833333,11.5,0.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#8fc3a6" }}
        d="M75.5,84.5C76.5,84.5,77.5,84.5,78.5,84.5C78.2643,87.7852,77.2643,90.7852,75.5,93.5C75.1244,90.6267,75.1244,87.6267,75.5,84.5Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1, fill: "#fdfdfd" }}
        d="M64.5,2.5C70.9648,8.46391,77.2981,14.6306,83.5,21C77.1754,21.4995,70.8421,21.6662,64.5,21.5C64.5,15.1667,64.5,8.83333,64.5,2.5Z"
      />
    </g>
  </svg>
);

export default CsvIcon;
