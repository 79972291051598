import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "./contexts/ThemeContext";
import { BrowserRouter } from "react-router-dom";
import { ConfirmDialogProvider } from "react-mui-confirm";
import { AuthProvider } from "./components/auth/AuthContext";
import GlobalProvider from "./contexts/GlobalContext";
import { setupInterceptors } from "./components/auth/TokenService";

const container = document.getElementById("root");
const root = createRoot(container);

setupInterceptors();

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <ConfirmDialogProvider>
        <AuthProvider>
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </AuthProvider>
      </ConfirmDialogProvider>
    </ThemeProvider>
  </BrowserRouter>
);
