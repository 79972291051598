// import { Sliders, Users } from "react-feather";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
// import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
// import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
// import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";

const pagesSection = [
  // {
  //   href: "/dashboard",
  //   icon: Sliders,
  //   title: "Dashboard",
  //   children: [
  //     {
  //       href: "/dashboard/default",
  //       title: "Default",
  //     },
  //   ],
  // },
  {
    href: "/VAT",
    icon: PercentOutlinedIcon,
    title: "VAT",
    expanded: true,
    children: [
      {
        href: "/VAT/ListClaims",
        title: "List Claims",
      },
      {
        href: "/invoices/ListInvoices",
        title: "List Invoices",
      },
    ],
  },
  {
    href: "/excise",
    icon: WaterDropOutlinedIcon,
    title: "Excise Duty",
    expanded: true,
    children: [
      {
        href: "/excise/ListExciseClaims",
        // icon: CardTravelOutlinedIcon,
        title: "List Excise Claims",
      },
    ],
  },
  // {
  //   href: "/documents",
  //   icon: TopicOutlinedIcon,
  //   title: "Documents",
  //   expanded: true,
  //   children: [
  //     {
  //       href: "/Documents/UploadInvoices",
  //       // icon: CardTravelOutlinedIcon,
  //       title: "Upload Invoices",
  //     },
  //   ],
  // },
  // {
  //   href: "/invoices",
  //   icon: InsertDriveFileOutlinedIcon,
  //   title: "Invoices",
  //   expanded: true,
  //   children: [
  //     {
  //       href: "/invoices/ListInvoices",
  //       title: "List Invoices",
  //     },
  //     // {
  //     //   href: "/invoices/NotClaimableInvoices",
  //     //   title: "Not Claimable Invoices",
  //     // },
  //   ],
  // },
];
// const SettingsSection = [
//   {
//     href: "/globalsettings",
//     icon: Users,
//     title: "Global Settings",
//     children: [
//       {
//         href: "/settings/globalsettings/suppliers",
//         title: "Suppliers",
//       },
//     ],
//   },
// ];

const navItems = [
  {
    title: "Menu",
    pages: pagesSection,
  },
  // {
  //   title: "Settings",
  //   pages: SettingsSection,
  // },
];

export default navItems;
