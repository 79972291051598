import React, { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const accessToken = Cookies.get("accessToken");
    const accessTokenExpiry = Cookies.get("accessTokenExpiry");
    if (accessToken && accessTokenExpiry) {
      const expirationDate = new Date(accessTokenExpiry);
      if (expirationDate > new Date()) {
        return true;
      } else {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        Cookies.remove("accessTokenExpiry");
        return false;
      }
    }
    return false;
  });

  const login = async (accessToken, refreshToken, expiresIn) => {
    const expirationTimestamp = new Date(Date.now() + expiresIn * 1000);
    Cookies.set("accessToken", accessToken, { expires: expirationTimestamp, secure: true, sameSite: "Strict" });
    Cookies.set("refreshToken", refreshToken, { expires: expirationTimestamp, secure: true, sameSite: "Strict" });
    Cookies.set("accessTokenExpiry", expirationTimestamp.toISOString(), {
      expires: expirationTimestamp,
      secure: true,
      sameSite: "Strict",
    });

    setIsAuthenticated(true);
    return Promise.resolve();
  };

  const logout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("accessTokenExpiry");

    setIsAuthenticated(false);
  };

  return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
