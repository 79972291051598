import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { darken } from "polished";
import { Grid, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar, Typography, MenuItem, Select } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { GlobalContext } from "../../contexts/GlobalContext";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const { companyName, isAccountManager, allowedCompanies, customerId, isLoading, updateCustomerIdAndNumber } =
    useContext(GlobalContext);
  const [selectedCompany, setSelectedCompany] = useState("");

  useEffect(() => {
    if (isAccountManager === 1 && customerId) {
      setSelectedCompany(customerId.toString());
    } else if (companyName) {
      setSelectedCompany(companyName);
    }
  }, [companyName, customerId, isAccountManager, isLoading]);

  const handleCompanyChange = async (event) => {
    const newCustomerId = event.target.value;
    setSelectedCompany(newCustomerId);
    await updateCustomerIdAndNumber(newCustomerId);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              {isAccountManager === 1 ? (
                <Select
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  renderValue={(selected) => {
                    const selectedCompanyObject = allowedCompanies.find((company) => company[0] === selected);
                    return selectedCompanyObject ? (
                      <Typography variant="h4" noWrap component="div" color={"#000"}>
                        {selectedCompanyObject[1]}
                      </Typography>
                    ) : null;
                  }}
                >
                  {allowedCompanies.map((company) => (
                    <MenuItem key={company[0]} value={company[0]}>
                      <Typography variant="h4" noWrap component="div" color={"#000"}>
                        {company[1]}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="h4" noWrap component="div" color={"#000"}>
                  {companyName || "Loading..."}
                </Typography>
              )}
            </Grid>
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle} size="large">
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Search>
                {/* <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} /> */}
              </Search>
            </Grid>
            <Grid item>
              <NavbarMessagesDropdown />
              <NavbarNotificationsDropdown />
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
