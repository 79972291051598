import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search topics…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
      "Add Invoices": "Add Invoices",
      "In this table you can add invoices in the system. Select the customer and supplier first.":
        "In this table you can add invoices in the system. Select the customer and supplier first.",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bon retour",
      "We've missed you": "Tu nous as manqué",
      "Add Invoices": "Ajouter des factures",
      "In this table you can add invoices in the system. Select the customer and supplier first.":
        "Dans ce tableau, vous pouvez ajouter des factures dans le système. Sélectionnez d'abord le client et le fournisseur.",
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
      "We've missed you": "Wir haben dich vermisst",
      "Add Invoices": "Rechnungen hinzufügen",
      "In this table you can add invoices in the system. Select the customer and supplier first.":
        "In dieser Tabelle können Sie Rechnungen im System hinzufügen. Wählen Sie zuerst den Kunden und den Lieferanten aus.",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welkom terug",
      "We've missed you": "We hebben je gemist",
      "Add Invoices": "Facturen toevoegen",
      "In this table you can add invoices in the system. Select the customer and supplier first.":
        "In deze tabel kunt u facturen toevoegen aan het systeem. Selecteer eerst de klant en de leverancier.",
    },
  },
  pl: {
    translation: {
      Search: "Szukaj...",
      "Welcome back": "Witaj z powrotem",
      "We've missed you": "Tęskniliśmy za tobą",
      "Add Invoices": "Dodaj faktury",
      "In this table you can add invoices in the system. Select the customer and supplier first.":
        "W tej tabeli możesz dodać faktury do systemu. Najpierw wybierz klienta i dostawcę.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
