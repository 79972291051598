import React, { createContext, useEffect, useState, useCallback } from "react";
import axios from "axios";

export const GlobalContext = createContext();

const hasRequiredRole = (roles, requiredRole) => {
  return roles.includes(requiredRole);
};

const GlobalProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [email, setEmail] = useState(null);
  const [roles, setRoles] = useState([]);
  const [allowedCompanies, setAllowedCompanies] = useState([]);
  const [userName, setUserName] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerNumber, setCustomerNumber] = useState(null);
  const [isAccountManager, setIsAccountmanager] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserData = useCallback(async () => {
    try {
      // console.log("Fetching user data...");
      const response = await axios.get("/api/v1/GetAppUserInformation/");
      const { id, firstname, lastname, userName, email, roles, allowedCompanies, isAccountManager } = response.data;

      // console.log("User data fetched:", response.data);

      const companyNameResponse = await axios.get(`/api/v1/GetEntityNameByAppUserId/${id}`);
      const { name, entityId, entityNumber } = companyNameResponse.data;

      // console.log("Company name fetched:", companyNameResponse.data);

      setUserId(id);
      setUserName(userName);
      setDisplayName(`${firstname} ${lastname}`);
      setEmail(email);
      setRoles(roles);
      setAllowedCompanies(allowedCompanies);
      setCompanyName(name);
      setCustomerId(entityId);
      setCustomerNumber(entityNumber);
      setIsAccountmanager(isAccountManager);
      setIsAuthorized(hasRequiredRole(roles, "PortalUser"));

      // console.log("State updated with fetched data.");
    } catch (error) {
      // console.error("Error fetching user info:", error);
    } finally {
      setIsLoading(false);
      // console.log("Loading state set to false");
    }
  }, []); // Empty dependency array ensures this function is stable and doesn't change on each render

  const updateCustomerIdAndNumber = useCallback(async (newCustomerId) => {
    // console.log("Updating customer ID and number..." + newCustomerId);
    try {
      const companyNameResponse = await axios.get(`/api/v1/GetEntityNameByEntityId/${newCustomerId}`);
      const { name, entityId, entityNumber } = companyNameResponse.data;
      setCustomerId(entityId);
      setCustomerNumber(entityNumber);
      setCompanyName(name);
      // console.log("State updated with fetched data.");
    } catch (error) {
      console.error("Error updating customer info:", error);
    }
  }, []); // Empty dependency array ensures this function is stable and doesn't change on each render

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    // console.log("GlobalProvider state updated:", { companyName, isLoading });
  }, [companyName, isLoading]);

  return (
    <GlobalContext.Provider
      value={{
        userId,
        displayName,
        email,
        roles,
        allowedCompanies,
        userName,
        isAuthorized,
        companyName,
        customerId,
        customerNumber,
        isAccountManager,
        fetchUserData,
        updateCustomerIdAndNumber,
        isLoading,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
