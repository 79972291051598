import axios from "axios";
import { useState, useEffect } from "react";

const fetchData = async (endpoint) => {
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const deleteData = async (endpoint) => {
  try {
    const response = await axios.delete(endpoint);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
};

const updateData = async (endpoint, dataToUpdate) => {
  try {
    const response = await axios.put(endpoint, dataToUpdate);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const useApiData = (endpoint) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDataFromEndpoint = async () => {
      const data = await fetchData(endpoint);
      setData(data);
    };
    fetchDataFromEndpoint();
  }, [endpoint]);

  return data;
};

const apiUtils = {
  fetchData,
  deleteData,
  updateData,
  useApiData,
};

export default apiUtils;
