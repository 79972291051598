import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import SelectWrapper from "./SelectBox";
import { useDropzone } from "react-dropzone";
import moment from "moment";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  customerId: Yup.string().required("Required"),
  invoiceNumber: Yup.string().required("Required"),
  invoiceDate: Yup.string().required("Required"),
  supplierId: Yup.string().required("Required"),
  countryCode: Yup.string().required("Required"),
  claimProductTypeId: Yup.string().required("Required"),
  currencyId: Yup.string().required("Required"),
  invoiceGrossAmount: Yup.string()
    .required("Required")
    .matches(/^[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers and dots are allowed."),
  invoiceVATAmountLocalCurrency: Yup.string()
    .required("Required")
    .matches(/^[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers and dots are allowed."),

  // invoiceVATAmountEUR: Yup.string().required("Required"),
  claimId: Yup.string().required("Required"),
  invoiceLabelId: Yup.string().required("Required"),
});

export default function ViewCustomerInvoiceDialog(props) {
  const { viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog, refreshGrid } = props;

  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  const [isNewFile, setIsNewFile] = useState(false);
  const [isRemovedFile, setIsRemovedFile] = useState(false);
  const [isUpdatedFile, setIsUpdatedFile] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [currentCountryCode, setCurrentCountryCode] = useState(viewCustomerInvoiceDialog?.params?.countryCode);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  // const handleRemoveFileClick = () => {
  //   setShowConfirmationDialog(true);
  // };

  var customerId = viewCustomerInvoiceDialog?.custInfo[0]?.customerId;
  // var customerNumber = viewCustomerInvoiceDialog?.custInfo[0]?.customerNumber;

  const [invoiceLabelData, setInvoiceLabelData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/invoiceLabels").then((response) => setInvoiceLabelData(response.data));
  }, []);

  const [invoiceStateData, setInvoiceStateData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/invoiceStates").then((response) => setInvoiceStateData(response.data));
  }, []);

  useEffect(() => {
    setCurrentCountryCode(viewCustomerInvoiceDialog?.params?.countryCode);
  }, [viewCustomerInvoiceDialog?.params?.countryCode]);

  useEffect(() => {
    setSelectedClaimDate(moment(viewCustomerInvoiceDialog?.params?.invoiceDate).format("YYYY-MM-DD"));
  }, [viewCustomerInvoiceDialog]);

  const [fileUrl, setFileUrl] = useState(null);

  const downloadFile = async (fileId) => {
    setFileUrl("");
    try {
      const response = await axios({
        url: "/api/v1/azureblobstorage/" + fileId,
        method: "GET",
        responseType: "blob",
      });
      const href = URL.createObjectURL(response.data);
      setFileUrl(href);
    } catch (error) {
      setFileUrl("NoFile");
      console.error("Error downloading file:", error);
    }
  };

  if (viewCustomerInvoiceDialog?.downloadFile) {
    const fileId = viewCustomerInvoiceDialog?.params?.fileId;
    // console.log("Download file: " + fileId);
    if (fileId === 0 || fileId === null) {
      setFileUrl("NoFile");
    } else {
      downloadFile(fileId);
    }
    viewCustomerInvoiceDialog.downloadFile = false;
  }

  const initialValues = {
    customerId: customerId,
    invoiceNumber: viewCustomerInvoiceDialog?.params?.invoiceNumber,
    invoiceDate: viewCustomerInvoiceDialog?.params?.invoiceDate,
    supplierId: viewCustomerInvoiceDialog?.params?.supplierId,
    countryCode: viewCustomerInvoiceDialog?.params?.countryCode,
    productTypeId: viewCustomerInvoiceDialog?.params?.productTypeId,
    claimProductTypeId: viewCustomerInvoiceDialog?.params?.claimProductTypeId,
    currencyId: viewCustomerInvoiceDialog?.params?.currencyId,
    invoiceGrossAmount: viewCustomerInvoiceDialog?.params?.invoiceGrossAmount,
    invoiceVATAmountLocalCurrency: viewCustomerInvoiceDialog?.params?.invoiceVATAmountLocalCurrency,
    invoiceVATAmountEUR: viewCustomerInvoiceDialog?.params?.invoiceVATAmountEUR,
    claimId: viewCustomerInvoiceDialog?.params?.claimId,
    invoiceLabelId: viewCustomerInvoiceDialog?.params?.invoiceLabelId,
    invoiceStateId: viewCustomerInvoiceDialog?.params?.invoiceStateId,
  };

  var errorMessage = "";
  var submitInError = false;

  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
    } catch (validationErrors) {
      const errors = {};

      validationErrors.inner.forEach((error) => {
        errors[error.path] = error.message;
      });

      setErrors(errors);
      return; // Stop the form submission
    }
    setSubmitting(true);
    await timeOut(1500);
    // console.log("ergreg erg erg er");
    let fileId = 0;
    let invoiceId = viewCustomerInvoiceDialog?.params?.customerInvoiceId;

    // console.log(invoiceId);
    // console.table(values);

    try {
      if (isNewFile || isUpdatedFile) {
        const fileInfo = {
          documentType: 1,
          creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
          createdBy: 1,
          fileName: fileContent.name,
          fileSize: fileContent.size,
          mimeType: fileContent.type,
        };

        if (isRemovedFile) {
          fileId = 0;
        } else {
          const response = await axios.post("/api/v1/file", fileInfo);
          // console.table(response);
          fileId = response.data.fileId;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", fileContent, fileId);
          await axios.post("/api/v1/azureblobstorage", data);
          const fileIdInfo = {
            fileId: fileId,
          };
          if (isUpdatedFile || isNewFile) {
            await axios.put("/api/v1/customerInvoices/updateFileId/" + invoiceId, fileIdInfo);
          }
        }
      } else {
        if (isRemovedFile) {
          fileId = 0;
        } else {
          fileId = viewCustomerInvoiceDialog?.params?.fileId;
        }
      }

      setIsNewFile(false);
      setIsRemovedFile(false);
      setIsUpdatedFile(false);

      const updatedInvoice = {
        entityId: customerId,
        invoiceNumber: values.invoiceNumber,
        invoiceDate: selectedClaimDate,
        supplierId: values.supplierId,
        countryCode: values.countryCode,
        claimProductTypeId: values.claimProductTypeId,
        currencyId: values.currencyId,
        invoiceGrossAmount: values.invoiceGrossAmount,
        invoiceVATAmountLocalCurrency: values.invoiceVATAmountLocalCurrency,
        // invoiceVATAmountEUR: values.invoiceVATAmountEUR,
        claimId: values.claimId,
        invoiceLabelId: values.invoiceLabelId,
        invoiceStateId: values.invoiceStateId,
        fileId: fileId,
      };
      // console.table(updatedInvoice);
      setEditedData(updatedInvoice);
      axios
        .put("/api/v1/customerInvoices/updateInvoiceEditForm/" + viewCustomerInvoiceDialog?.params?.customerInvoiceId, updatedInvoice)
        .then((response) => {
          viewCustomerInvoiceDialog.handleFile(updatedInvoice);
          // console.log("Update OK!");
        })
        .catch(function (error) {
          console.log("Update error!");
        })
        .finally(() => {
          refreshGrid();
        });

      viewCustomerInvoiceDialog.params.fileId = fileId;
      viewCustomerInvoiceDialog.handleFile(editedData);
      setSubmitting(false);
    } catch (error) {
      alert(error);
      setSubmitting(false);
    } finally {
      refreshGrid();
    }
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "300px",
    minWidth: "600px",
    marginTop: "200px",
    marginLeft: "200px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      const href = URL.createObjectURL(selectedFile);
      setFileUrl(href);
      if (isRemovedFile) {
        if (!isNewFile) {
          setIsUpdatedFile(true);
        }
      } else {
        setIsNewFile(true);
      }
      setFileContent(selectedFile);
      setIsRemovedFile(false);
      refreshGrid();
    },
    [isRemovedFile, isNewFile, refreshGrid]
  );

  function StyledDropzone(props) {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      accept: { "application/pdf": [] },
      onDropAccepted,
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          <p
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            No files are currently attached to this invoice. <br />
            Drag 'n' drop the invoice file here, or click to select
          </p>
        </div>
      </div>
    );
  }

  const handleRemoveFile = () => {
    setIsRemovedFile(true);
    setFileUrl("NoFile");
    refreshGrid();
    setShowConfirmationDialog(false);
  };

  // const handleSelectedClaimDateValue = (event) => {
  //   setSelectedClaimDate(event.target.value);
  // };

  const handleSelectedCountryChange = (event) => {
    setCurrentCountryCode(event.target.value);
  };

  return (
    <Dialog
      open={viewCustomerInvoiceDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">{viewCustomerInvoiceDialog.title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
            <Card mb={6}>
              <CardContent>
                {submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : null}

                {isSubmitting ? (
                  <Box minHeight="700px" display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={3} xs={12}>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            name="invoiceNumber"
                            label="Invoice number"
                            value={values.invoiceNumber}
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            name="invoiceDate"
                            label="Invoice date"
                            value={values.invoiceDate}
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            name="invoiceStateId"
                            label="Invoice State"
                            value={
                              invoiceStateData.find((State) => State.invoiceStateId === values.invoiceStateId)
                                ?.invoiceStateDescription || "N/A"
                            }
                            error={Boolean(touched.invoiceStateId && errors.invoiceStateId)}
                            fullWidth
                            helperText={touched.invoiceStateId && errors.invoiceStateId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            name="invoiceLabelId"
                            label="Invoice Label"
                            value={
                              invoiceLabelData.find((label) => label.invoiceLabelId === values.invoiceLabelId)
                                ?.invoiceLabelDescription || "N/A"
                            }
                            error={Boolean(touched.invoiceLabelId && errors.invoiceLabelId)}
                            fullWidth
                            helperText={touched.invoiceLabelId && errors.invoiceLabelId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            disabled
                            name="supplierId"
                            label="Supplier"
                            options={JSON.parse(
                              JSON.stringify(JSON.parse(JSON.stringify(props.supplierArr).split('"supplierId":').join('"key":')))
                                .split('"supplierName":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            disabled
                            name="countryCode"
                            label="Country"
                            onChange={handleSelectedCountryChange}
                            options={JSON.parse(
                              JSON.stringify(JSON.parse(JSON.stringify(props.countryArr).split('"countryId":').join('"key":')))
                                .split('"countryCode":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          {props.productTypeArr && props.productTypeArr.length > 0 && currentCountryCode ? (
                            <SelectWrapper
                              disabled
                              name="claimProductTypeId"
                              label="Product Type"
                              options={props.productTypeArr
                                .filter((item) => item.countryCode === currentCountryCode)
                                .map((item) => ({
                                  key: item.productTypeId,
                                  value:
                                    item.productLongDescription === "" ? item.productShortDescription : item.productLongDescription,
                                }))}
                            />
                          ) : (
                            <CircularProgress />
                          )}
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            disabled
                            name="currencyId"
                            label="Currency"
                            options={JSON.parse(
                              JSON.stringify(JSON.parse(JSON.stringify(props.currencyArr).split('"currencyId":').join('"key":')))
                                .split('"isoCode":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            name="invoiceGrossAmount"
                            label="Invoice gross amount"
                            value={values.invoiceGrossAmount}
                            error={Boolean(touched.invoiceGrossAmount && errors.invoiceGrossAmount)}
                            fullWidth
                            helperText={touched.invoiceGrossAmount && errors.invoiceGrossAmount ? errors.invoiceGrossAmount : ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            name="invoiceVATAmountLocalCurrency"
                            label="Invoice VAT amount"
                            value={values.invoiceVATAmountLocalCurrency}
                            error={Boolean(touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency)}
                            fullWidth
                            helperText={
                              touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency
                                ? errors.invoiceVATAmountLocalCurrency
                                : ""
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>

                        {/* <Grid item xs={12}>
                          <TextField
                            name="invoiceVATAmountEUR"
                            label="Invoice VAT amount EUR"
                            value={values.invoiceVATAmountEUR}
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid> */}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Grid item md={9} xs={12}>
                          {fileUrl === "" ? (
                            <div className="App">
                              <Typography
                                sx={{
                                  fontSize: "21px",
                                  mt: 2,
                                  pt: "394px",
                                  pb: "394px",
                                  display: "flex",
                                  justifyContent: "center",
                                  border: 1,
                                  borderColor: "lightgray",
                                  width: "1000px",
                                }}
                              >
                                <CircularProgress size={30} sx={{ mr: 2 }} />
                                Loading file, please wait!
                              </Typography>
                            </div>
                          ) : fileUrl === "NoFile" || fileUrl === null ? (
                            <div className="App">
                              <StyledDropzone />
                            </div>
                          ) : (
                            <div className="App">
                              <div style={{ display: "flex", width: "1015px" }}>
                                {/* <Button
                                  display="flex"
                                  type="button"
                                  variant="contained"
                                  color="error"
                                  mt={3}
                                  my={2}
                                  mr={4}
                                  onClick={handleRemoveFileClick}
                                  style={{ marginLeft: "auto" }}
                                >
                                  Remove file
                                </Button> */}
                                <Dialog open={showConfirmationDialog} onClose={handleConfirmationDialogClose}>
                                  <DialogTitle>Confirmation</DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>Are you sure you want to delete the invoice copy?</DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleConfirmationDialogClose} color="primary">
                                      Cancel
                                    </Button>
                                    <Button onClick={handleRemoveFile} color="primary">
                                      Delete
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                              <object
                                width={1000}
                                height={800}
                                data={fileUrl}
                                type="application/pdf"
                                style={{
                                  marginTop: "8px",
                                }}
                              >
                                <iframe title="file" src={fileUrl + "&embedded=true"}></iframe>
                              </object>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      mr={4}
                    >
                      Update Invoice
                    </Button> */}
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      onClick={() =>
                        setViewCustomerInvoiceDialog({
                          ...viewCustomerInvoiceDialog,
                          isOpen: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
