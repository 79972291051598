import axios from "axios";
import Cookies from "js-cookie";

export const setupInterceptors = () => {
  axios.interceptors.request.use(
    async (config) => {
      let accessToken = Cookies.get("accessToken");
      let accessTokenExpiry = new Date(Cookies.get("accessTokenExpiry")).getTime();

      const now = Date.now();
      const isAccessTokenExpired = !accessTokenExpiry || now >= accessTokenExpiry;

      if (isAccessTokenExpired) {
        const refreshToken = Cookies.get("refreshToken");

        if (!refreshToken) {
          // console.error("No refresh token available.");
          return Promise.reject("No refresh token available.");
        }

        try {
          const refreshRequestBody = JSON.stringify({ refreshToken });

          // Log the request payload
          console.log("Refreshing token with payload:", refreshRequestBody);

          const response = await fetch("/api/refresh", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: refreshRequestBody,
          });

          if (!response.ok) {
            throw new Error(`Failed to refresh token: ${response.statusText}`);
          }

          const responseData = await response.json();
          const accessTokenNew = responseData.accessToken;
          const refreshTokenNew = responseData.refreshToken;
          const expiresInNew = responseData.expiresIn;

          const newExpiryTime = new Date(now + expiresInNew * 1000).toISOString();
          Cookies.set("accessToken", accessTokenNew, { secure: true, sameSite: "Strict" });
          Cookies.set("refreshToken", refreshTokenNew, { secure: true, sameSite: "Strict" });
          Cookies.set("accessTokenExpiry", newExpiryTime, { secure: true, sameSite: "Strict" });

          accessToken = accessTokenNew;
        } catch (error) {
          console.error("Error refreshing token:", error);
          return Promise.reject(error);
        }
      }

      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};
