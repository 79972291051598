import React, { useState, useEffect, useCallback, useContext } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid, Box, Card as MuiCard, Typography, Divider, IconButton, CircularProgress, Stack, Tabs, Tab } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewExciseClaimDialog from "../../components/ViewExciseClaimDialog";
import DataGridExport from "../../components/DataGridExport";
import { GlobalContext } from "../../contexts/GlobalContext";

const Card = styled(MuiCard)(spacing);

const ListExciseClaims = (props) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { customerId, customerNumber } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(0); // 0 for 'All Claims' tab
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchAndFilterClaims = useCallback(async () => {
    if (!customerId) {
      // console.error("customerId is not set");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.get(`/api/v1/exciseClaimsByEntityId/${customerId}`);
      // Filter and set the table data here based on the selected tab
      let filteredClaims = response.data;
      setTableData(filteredClaims);
    } catch (error) {
      console.error("Error fetching claims by entity ID:", error);
    } finally {
      setLoading(false);
    }
  }, [customerId]); // Ensure all dependencies are listed here

  useEffect(() => {
    if (customerId) {
      fetchAndFilterClaims();
    }
  }, [customerId, fetchAndFilterClaims]); // Add fetchAndFilterClaims to the dependency array

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("/api/v1/countries");
      setCountryData(response.data);
    };
    fetchData();
  }, []);

  const columns = [
    {
      field: "claimId",
      headerName: "Claim ID",
      width: 50,
      hide: true,
    },
    { field: "claimCode", headerName: "Claim Code", flex: 0.5 },
    { field: "year", headerName: "Year", flex: 0.5 },
    {
      field: "startMonth",
      headerName: "Start Month",
      flex: 0.5,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "endMonth",
      headerName: "End Month",
      flex: 0.5,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "countryId",
      headerName: "Country",
      flex: 0.5,
      valueGetter: (params) => countryData.find((country) => country.countryId === params.row.countryId).countryCode,
    },
    {
      field: "liters",
      headerName: "Liters",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimAmountEUR",
      headerName: "Claim Amount",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimAmountLocal",
      headerName: "Claim Amount Local",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "View",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setViewExciseClaimDialog({
                  isOpen: true,
                  title: "View Excise Claim",
                  params: params.row,
                  countryData: countryData,
                  downloadFile: true,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  subTitle: "Are you sure you want to delete " + params.row.claimCode + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewExciseClaimDialog, setViewExciseClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  function onEditConfirmed(e, params) {
    window.location.reload(false);

    setViewExciseClaimDialog({ ...viewExciseClaimDialog, isOpen: false });
  }

  const [pageSize, setPageSize] = useState(15);

  return (
    <React.Fragment>
      <Helmet title="Claims" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item></Grid>
      </Grid>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Claim Tabs" sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tab label="All Excise Claims" sx={{ typography: "h6", fontWeight: "bold" }} />
      </Tabs>
      <Card mb={6}>
        <Notification notify={notify} setNotify={setNotify} />
        <Divider />
        <Grid container spacing={2} mt={0}>
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewExciseClaimDialog
            viewExciseClaimDialog={viewExciseClaimDialog}
            setViewExciseClaimDialog={setViewExciseClaimDialog}
            refreshTable={fetchAndFilterClaims}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              countryData.length > 0 && (
                <DataGrid
                  getRowId={(row) => row.claimId}
                  disableSelectionOnClick
                  disableColumnSelector
                  components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        No Claims Found
                      </Stack>
                    ),
                    Toolbar: GridToolbar,
                  }}
                  density="compact"
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  rows={tableData}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[15, 50, 100]}
                  sx={{
                    height: 667,
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    marginTop: 0,
                  }}
                />
              )
            )}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default ListExciseClaims;
