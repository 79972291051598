import React, { useState } from "react";
import { Button, TextField, CircularProgress } from "@mui/material";
import "./LoginScreen.css";
import "../../CSS/fonts.css";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

function SignIn() {
  let devUserName = "";
  let devPassword = "";

  if (process.env.NODE_ENV === "development") {
    devUserName = process.env.REACT_APP_DEV_USER_NAME;
    devPassword = process.env.REACT_APP_DEV_USER_PASSWORD;
  } else {
    devUserName = "";
    devPassword = "";
  }

  const [username, setUsername] = useState(devUserName);
  const [password, setPassword] = useState(devPassword);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false); // State to track loading status
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    setLoginError("");
    setLoading(true); // Start loading
    try {
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        await login(data.accessToken, data.refreshToken, data.expiresIn);
        navigate("/", { replace: true }); // Use replace to avoid navigation stack issues
      } else {
        throw new Error("Failed to login");
      }
    } catch (error) {
      setLoginError(error.message);
    } finally {
      setLoading(false); // Stop loading regardless of outcome
    }
  };

  return (
    <div className="split-screen">
      <div className="left-pane">
        <div className="content">
          <img src="/static/img/delta-customer-portal-blue.png" alt="Delta Customer Portal" className="login-logo" />
          <div className="main-text-container">
            <p className="login-instruction" style={{ fontFamily: "'Helvetica Neue Light', sans-serif" }}>
              Welkom bij het Delta Klantenportaal! Gelieve in te loggen.
            </p>
          </div>
          <div
            className="button-container"
            style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}
          >
            {loginError && (
              <div
                style={{
                  fontFamily: "'Helvetica Neue Light', sans-serif",
                  fontSize: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-start",
                  color: "red",
                }}
              >
                {loginError}
              </div>
            )}
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ marginBottom: "1rem" }}
              disabled={loading}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginBottom: "10px" }}
              disabled={loading}
            />
            <a
              href="https://deltarefundsolutions.com/Contact"
              className="forgot-password"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontFamily: "'Helvetica Neue Light', sans-serif", marginBottom: "40px", alignSelf: "flex-start" }}
            >
              Wachtwoord vergeten?
            </a>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Button className="login-button" onClick={handleLogin} variant="contained" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Inloggen"}
              </Button>
            </div>
          </div>

          <div className="extra-links extra-link-container">
            <a
              href="https://deltarefundsolutions.com/Contact"
              className="extra-link"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontFamily: "'Helvetica Neue Light', sans-serif" }}
            >
              U bent al klant, maar kunt niet inloggen?
            </a>
            <a
              href="https://deltarefundsolutions.com/Contact"
              className="extra-link"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontFamily: "'Helvetica Neue Light', sans-serif" }}
            >
              U wilt klant worden?
            </a>
          </div>
        </div>
      </div>
      <div className="right-pane">{/* Background image set via CSS */}</div>
    </div>
  );
}

export default SignIn;
